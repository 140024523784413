import styled from '@emotion/styled';

export const Absolute = styled.div<{ top?: string; bottom?: string; right?: string; left?: string }>`
position: absolute;
top: ${({ top }) => top};
bottom: ${({ bottom }) => bottom};
right: ${({ right }) => right};
left: ${({ left }) => left};

`;
