import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const TitleH1 = styled.h1<{ color?: string }>`
  text-transform: uppercase;
  font-family: ${theme.fontsFamily.montserrat.name};
  font-weight: ${theme.fontsFamily.montserrat.medium};
  font-size: 30px;
  margin-bottom: 3rem;
  ${({ color }) => ({
    [theme.mediaQueries.md]: {
      fontSize: '40px',
      lineHeight: '60px',
      color: color || theme.colors.darkBlue,
      fontWeight: theme.fontsFamily.montserrat.medium as any
    }
  })}
  color: ${({ color }) => color || theme.colors.orange};
  text-align: center;
`;
