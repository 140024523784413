import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import { GetStaticPropsType } from 'types/globals.type';
import Head from 'next/head';
import Layout from 'components/Layout/Layout';
import { MindsApp } from 'components/MindsApp';
import { defaultTranslationImports } from '../utils/defaultTranslationImports';
import { NotFound } from 'components/NotFound/NotFound';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{`${t('header.mindsapp')} - ${t('notFound')}`}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="google-site-verification"
          content="Vvy8cFzJAK4i_PQ15z846Q1IUGWaUxF0yXPy694Zv5w"
        />
      </Head>
      <Layout defaultHeader>
        <NotFound />
      </Layout>
    </>
  );
};

export const getStaticProps = async ({
  locale,
}: Record<string, any>): Promise<GetStaticPropsType> => ({
  props: {
    ...(await serverSideTranslations(locale, [...defaultTranslationImports])),
  },
});

export default NotFoundPage;
