import { Article } from 'components/commons/Article';
import { Section } from 'components/commons/Section';
import { TitleH1 } from 'components/Typo/TitleH1';
import React from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { theme } from 'styles/theme';
import styled from '@emotion/styled';
import { Absolute } from 'components/commons/Absolute';
import { Button } from 'components/commons/Button';
import Link from 'next/link';
// import Animation from 'components/assets/animation.svg';

const ForOFor = styled.h1`
  font-size: 10rem;
  text-align: center;
  color: ${theme.colors.white};
  margin-top: 10rem;
  ${{
    [theme.mediaQueries.md]: {
      marginTop: '5rem',
    },
  }}
`;

const CustomSection = styled(Section)`
  margin-top: -10rem;
  padding: 0 2rem;
  ${{
    [theme.mediaQueries.md]: {
      marginTop: '10rem',
    },
  }}
`;

const ObjectSVG = styled.object`
  max-height: 40rem;
`;

const Anchor = styled.a`
  color: ${theme.colors.white};
  &:hover {
    color: ${theme.colors.white};
  }
`;

export const NotFound = () => {
  const { t } = useTranslation('notfound');
  return (
    <Article>
      <CustomSection
        margin="10rem 0 0 0"
        backgroundColor={theme.colors.darkBlue}
        flexDirection="column"
      >
        <ForOFor>404</ForOFor>
        <TitleH1 color={theme.colors.white}>{t('title')}</TitleH1>
        <Absolute bottom="3rem" right="3rem">
          <Image
            src="/assets/pictos/mindsAppWhiteLogo.svg"
            alt="Mindsapp logo"
            width={300}
            height={300 / 0.97}
            quality={100}
            priority
            layout="fixed"
          />
        </Absolute>

        <Button
          backgroundColor={theme.colors.orange}
          hover={theme.colors.blueGreen}
        >
          <Link href="/">
            <Anchor>{t('goBackHomepage')}</Anchor>
          </Link>
        </Button>
        {/* <Animation /> */}
        <ObjectSVG type="image/svg+xml" data="/assets/pictos/animated.svg">
          Broken node
        </ObjectSVG>
      </CustomSection>
    </Article>
  );
};
